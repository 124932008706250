// src/components/Layout.js
import React from "react";
import Header from "./Header";
import HexagonBackground from "./HexagonBackground";

function Layout({ children }) {
  return (
    <div className="mi-wrapper">
      <HexagonBackground />
      <Header />
      {children}
    </div>
  );
}

export default Layout;
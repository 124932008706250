import React, { useEffect, useRef } from 'react';

const HexagonBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let fullHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );

    const isLightMode = document.body.classList.contains('light');

    const resize = () => {
      const width = window.innerWidth;
      fullHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      
      const scale = window.devicePixelRatio || 1;
      canvas.width = width * scale;
      canvas.height = fullHeight * scale;
      canvas.style.width = `${width}px`;
      canvas.style.height = `${fullHeight}px`;
      ctx.scale(scale, scale);
      initGrid();
    };

    // Hexagon properties
    const hexSize = 15;
    const hexWidth = hexSize * 2;
    const hexHeight = Math.sqrt(3) * hexSize;
    let mouseX = -1000;
    let mouseY = -1000;

    let hexagons = [];
    function initGrid() {
      hexagons = [];
      const cols = Math.ceil(canvas.width / (hexWidth * 0.75)) + 2;
      const rows = Math.ceil(fullHeight / hexHeight) + 2;
      
      for(let y = 0; y < rows; y++) {
        for(let x = 0; x < cols; x++) {
          const xPos = x * hexWidth * 0.75;
          const yPos = y * hexHeight + (x % 2 ? hexHeight/2 : 0);
          hexagons.push({
            x: xPos,
            y: yPos,
            brightness: 0
          });
        }
      }
    }

    function drawHexagon(x, y, size, brightness) {
      if (brightness <= 0.01) return;
      
      ctx.beginPath();
      for(let i = 0; i < 6; i++) {
        const angle = i * Math.PI / 3;
        const xPos = x + size * Math.cos(angle);
        const yPos = y + size * Math.sin(angle);
        if(i === 0) ctx.moveTo(xPos, yPos);
        else ctx.lineTo(xPos, yPos);
      }
      ctx.closePath();
      
      const color = isLightMode
        ? `rgba(100, 100, 100, ${brightness * 0.3})`
        : `rgba(0, 156, 255, ${brightness * 0.5})`;
      
      ctx.strokeStyle = color;
      ctx.lineWidth = isLightMode ? 0.5 : 1;
      ctx.stroke();

      if (brightness > 0.1) {
        ctx.fillStyle = isLightMode
          ? `rgba(200, 200, 200, ${brightness * 0.1})`
          : `rgba(0, 156, 255, ${brightness * 0.2})`;
        ctx.fill();
      }
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      hexagons.forEach(hex => {
        const dx = mouseX - hex.x;
        const dy = mouseY - hex.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const maxDistance = 150;
        
        if(distance < maxDistance) {
          hex.brightness = 1 - (distance / maxDistance);
        } else {
          hex.brightness *= 0.95;
        }

        drawHexagon(hex.x, hex.y, hexSize, hex.brightness);
      });

      animationFrameId = requestAnimationFrame(animate);
    }

    // Initialize
    resize();
    animate();

    // Handle content height changes
    const resizeObserver = new ResizeObserver(() => {
      resize();
    });
    resizeObserver.observe(document.body);

    // Event listeners
    window.addEventListener('resize', resize);
    
    const handleMouseMove = (e) => {
      const rect = canvas.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      
      // Adjust mouse position calculation
      const scale = window.devicePixelRatio || 1;
      mouseX = (e.clientX - rect.left) * scale;
      mouseY = (e.clientY + scrollTop) * scale;
    };

    const handleMouseLeave = () => {
      mouseX = -1000;
      mouseY = -1000;
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseleave', handleMouseLeave);
    window.addEventListener('scroll', () => {
      const rect = canvas.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      mouseY = (rect.top + scrollTop) * (window.devicePixelRatio || 1);
    });

    return () => {
      window.removeEventListener('resize', resize);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseleave', handleMouseLeave);
      resizeObserver.disconnect();
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute', // Changed from fixed to absolute
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: document.body.classList.contains('light') ? '#ffffff' : '#1a1a1a',
        zIndex: -2,
        pointerEvents: 'none'
      }}
    />
  );
};

export default HexagonBackground;
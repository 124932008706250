import Mock from "../mock";

const database = {
  information: {
    name: "WebPress Solutions",
    aboutContent: `WebPress Solutions is a forward-thinking software development company specializing in creating innovative digital solutions. Founded with a vision to transform how businesses connect with their communities, the company has achieved notable success with projects like Oorsee.com, a groundbreaking social media platform connecting South African expats worldwide.

Through a combination of cutting-edge technology and user-focused development, WebPress Solutions builds digital platforms that drive real-world engagement and business growth. The company's expertise spans from community platforms to enterprise solutions, consistently delivering projects that exceed client expectations.

Led by experienced developers and digital strategists, WebPress Solutions leverages modern technologies like React, Python, and Streamlit to create seamless, scalable applications. The team's commitment to innovation and quality has resulted in successful deployments across various sectors, from social media platforms to healthcare solutions.`,
    age: "",
    phone: "",
    nationality: "Australian Based",
    language: "English, Afrikaans",
    email: "steven@oorsee.com",
    address: "Melbourne, Australia",
    freelanceStatus: "Available for select projects",
    socialLinks: {
      facebook: "https://www.facebook.com/WebPressNinja/",
      github: "https://github.com/StevenPhyffer",
    },
    brandImage: "/images/brand-image.jpg",
    aboutImage: "/images/about-image.jpg",
    aboutImageLg: "/images/about-image-lg.jpg",
    cvfile: "/files/empty.pdf"
  },
  services: [
    {
      title: "Platform Development",
      icon: "code",
      details: `Specializing in building scalable digital platforms using React and modern JavaScript. The team creates robust solutions that handle high user loads while maintaining optimal performance. From social networks to enterprise applications, WebPress Solutions delivers platforms that grow with your business.`
    },
    {
      title: "Full Stack Development",
      icon: "layers",
      details: `Delivering comprehensive full-stack solutions using React, Python, and Streamlit. The company excels in creating data-driven applications, real-time communication systems, and robust backend infrastructures that power modern web applications. Each solution is built with scalability and maintenance in mind.`
    },
    {
      title: "Digital Solutions",
      icon: "monitor",
      details: `Creating custom digital solutions that solve real business challenges. WebPress Solutions combines technical expertise with business acumen to develop features that drive user engagement, facilitate connections, and build lasting digital ecosystems. Each project is tailored to meet specific business objectives.`
    }
  ],
  skills: [
    {
      title: "React/React Native",
      value: 90
    },
    {
      title: "Python/Streamlit",
      value: 85
    },
    {
      title: "Platform Development",
      value: 95
    },
    {
      title: "API Integration",
      value: 90
    },
    {
      title: "UI/UX Design",
      value: 85
    },
    {
      title: "System Architecture",
      value: 85
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Oorsee Platform",
      subtitle: "Social Platform for South African Expats",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: "https://oorsee.com",
      details: "Developed a comprehensive social media platform connecting South African expats worldwide. The platform features advanced community engagement tools, event management systems, and business integration capabilities."
    },
    {
      id: 2,
      title: "Melbourne Chiropractic Network",
      subtitle: "Healthcare Provider Web Platform",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: "#",
      details: "Developed and maintains a network of healthcare provider websites across Melbourne. Implemented comprehensive SEO strategies achieving top Google rankings and integrated analytics for patient acquisition tracking."
    },
    {
      id: 3,
      title: "Global Auto Auction Platform",
      subtitle: "International Vehicle Trading System",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: "#",
      details: "Engineered a custom auction platform facilitating international vehicle sales. Features include real-time bidding systems, secure payment processing, and comprehensive vehicle management capabilities."
    },
    {
      id: 4,
      title: "Enterprise Budget Manager",
      subtitle: "Financial Planning Software",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: "#",
      details: "Created sophisticated budgeting software for U.S.-based accounting firms. The system features automated calculations, forecast modeling, and detailed financial reporting capabilities."
    },
    {
      id: 5,
      title: "Healthcare Analytics Dashboard",
      subtitle: "Patient Management System",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"],
      details: "Developed a custom analytics platform for healthcare providers, enabling efficient tracking of patient engagement, appointment scheduling, and treatment outcomes."
    },
    {
      id: 6,
      title: "Financial Forecasting Tool",
      subtitle: "Business Planning Application",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"],
      details: "Built an advanced financial planning tool for American businesses, featuring comprehensive budget forecasting, cash flow analysis, and financial goal tracking capabilities."
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2021 - Present",
        position: "Platform Development",
        company: "Oorsee.com",
        details: `Successfully developed and scaled Oorsee.com, a social media platform connecting South African expats worldwide. Implemented advanced features including group interactions, event planning, and sponsorship systems. Continues to enhance the platform through technical innovation and community-driven development.`
      },
      {
        id: 2,
        year: "2021 - Present",
        position: "Digital Content Management",
        company: "Oorsee YouTube Channel",
        details: `Established and maintains the Oorsee YouTube Channel content strategy. Created a successful digital media presence reaching thousands of subscribers. Developed an integrated approach combining platform features with engaging video content.`
      },
      {
        id: 3,
        year: "2018 - Present",
        position: "Software Development",
        company: "WebPress Solutions",
        details: `Leading development of various web and mobile applications for diverse clients. Focuses on creating user-centric solutions and understanding specific industry needs. Established a strong track record of successful project deliveries and client satisfaction.`
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "Ongoing",
        graduation: "Professional Development",
        university: "Technology & Innovation",
        details: "Continuous advancement in modern web technologies, platform development, and digital solution architectures."
      }
    ]
  },
  reviews: [
    {
      id: 1,
      content: "WebPress Solutions transformed our digital presence with their innovative approach and technical expertise. Their platform development capabilities exceeded our expectations.",
      author: {
        name: "Healthcare Provider",
        designation: "Client"
      }
    },
    {
      id: 2,
      content: "The team's attention to detail and understanding of our business needs resulted in a solution that perfectly matched our requirements. Their ongoing support has been invaluable.",
      author: {
        name: "Financial Services Director",
        designation: "Enterprise Client"
      }
    },
    {
      id: 3,
      content: "Working with WebPress Solutions has significantly improved our digital capabilities. Their technical expertise and business understanding delivered real results.",
      author: {
        name: "Business Owner",
        designation: "Platform Client"
      }
    }
  ],
  blogs: [
    {
      id: 1,
      title: "Modern Platform Development Strategies",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/building-community.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2024"
    },
    {
      id: 2,
      title: "Digital Solutions: From Concept to Deployment",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/oorsee-story.md",
      createDay: "15",
      createMonth: "January",
      createYear: "2024"
    },
    {
      id: 3,
      title: "Building Scalable Web Applications",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/connecting-expats.md",
      createDay: "10",
      createMonth: "January",
      createYear: "2024"
    }
  ]
};

Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});